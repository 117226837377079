import React from "react";
import { motion } from "framer-motion";

const IconMap = {
	addTile: AddTile,
	recordOverlay: RecordOverlay,
	comments: Comments,
	keyboardShortcuts: KeyboardShortcuts,
	mobilePreview: MobilePreview,
};

const iconVariants = {
	hover: {
		fill: "rgba(255,255,255,0.4)",
		transition: { duration: 0.1 },
	},
	default: {
		fill: "rgba(255,255,255,0.4)",
		transition: { duration: 0.1 },
	},
	active: {
		fill: "#ED00EB",
		transition: { duration: 0.1 },
	},
};

export const Icon = props => {
	const defaultColor = "rgba(255,255,255,0.4)";
	const { name, color = defaultColor, size = 28, ...rest } = props;
	const Drawing = IconMap[name] ? IconMap[name] : null;

	return (
		<svg width={size} height={size} {...rest} viewBox="0 0 28 28">
			<Drawing color={color} />
		</svg>
	);
};

function AddTile(props) {
	return (
		<motion.path
			variants={iconVariants}
			d="M15.0937 6.125C15.0937 5.52094 14.604 5.03125 14 5.03125C13.3959 5.03125 12.9062 5.52094 12.9062 6.125V12.9062H6.125C5.52094 12.9062 5.03125 13.3959 5.03125 14C5.03125 14.604 5.52094 15.0937 6.125 15.0937H12.9062V21.875C12.9062 22.4791 13.3959 22.9688 14 22.9688C14.604 22.9688 15.0937 22.4791 15.0937 21.875V15.0937H21.875C22.4791 15.0937 22.9688 14.604 22.9688 14C22.9688 13.3959 22.4791 12.9062 21.875 12.9062H15.0937V6.125Z"
		/>
	);
}

function RecordOverlay(props) {
	return (
		<g>
			<motion.path d="M14 18.375a4.375 4.375 0 100-8.75 4.375 4.375 0 000 8.75z" variants={iconVariants} />
			<motion.path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M24.5 14c0 5.799-4.701 10.5-10.5 10.5S3.5 19.799 3.5 14 8.201 3.5 14 3.5 24.5 8.201 24.5 14zm-1.75 0a8.75 8.75 0 11-17.5 0 8.75 8.75 0 0117.5 0z"
				variants={iconVariants}
			/>
		</g>
	);
}

function Comments(props) {
	return (
		<motion.path
			fillRule="evenodd"
			clipRule="evenodd"
			variants={iconVariants}
			d="M4.375 16.83V9.626a3.5 3.5 0 013.5-3.5h12.25a3.5 3.5 0 013.5 3.5v7a3.5 3.5 0 01-3.5 3.5h-6.683a.875.875 0 00-.56.203L9.31 23.304a.875.875 0 01-1.435-.672V20.33a.206.206 0 00-.206-.206 3.294 3.294 0 01-3.294-3.294zm3.938-5.455c0-.483.391-.875.874-.875h9.626a.875.875 0 110 1.75H9.188a.875.875 0 01-.876-.875zM9.187 14a.875.875 0 000 1.75h7a.875.875 0 100-1.75h-7z"
		/>
	);
}

function KeyboardShortcuts(props) {
	return (
		<g>
			<motion.path
				variants={iconVariants}
				d="M8.313 10.063a.438.438 0 00-.438.437v.875c0 .242.196.438.438.438h.874a.438.438 0 00.438-.438V10.5a.438.438 0 00-.438-.438h-.874zM8.313 13.125a.438.438 0 00-.438.438v.874c0 .242.196.438.438.438h.874a.438.438 0 00.438-.438v-.874a.438.438 0 00-.438-.438h-.874zM7.875 16.625c0-.242.196-.438.438-.438h.874c.242 0 .438.196.438.438v.875a.438.438 0 01-.438.438h-.874a.438.438 0 01-.438-.438v-.875zM11.813 16.188a.438.438 0 00-.438.437v.875c0 .242.196.438.438.438h4.374a.438.438 0 00.438-.438v-.875a.438.438 0 00-.438-.438h-4.375zM18.375 16.625c0-.242.196-.438.438-.438h.875c.241 0 .437.196.437.438v.875a.438.438 0 01-.438.438h-.875a.438.438 0 01-.437-.438v-.875zM11.813 10.063a.438.438 0 00-.438.437v.875c0 .242.196.438.438.438h.874a.438.438 0 00.438-.438V10.5a.438.438 0 00-.438-.438h-.874zM11.375 13.563c0-.242.196-.438.438-.438h.874c.242 0 .438.196.438.438v.874a.438.438 0 01-.438.438h-.874a.438.438 0 01-.438-.438v-.874zM15.313 10.063a.438.438 0 00-.438.437v.875c0 .242.196.438.438.438h.874a.438.438 0 00.438-.438V10.5a.438.438 0 00-.438-.438h-.875zM14.875 13.563c0-.242.196-.438.438-.438h.874c.242 0 .438.196.438.438v.874a.438.438 0 01-.438.438h-.875a.438.438 0 01-.437-.438v-.874zM18.813 10.063a.438.438 0 00-.438.437v.875c0 .242.196.438.438.438h.875a.438.438 0 00.437-.438V10.5a.438.438 0 00-.438-.438h-.875zM18.375 13.563c0-.242.196-.438.438-.438h.875c.241 0 .437.196.437.438v.874a.438.438 0 01-.438.438h-.875a.438.438 0 01-.437-.438v-.874z"
			/>
			<motion.path
				variants={iconVariants}
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.375 10.063a3.5 3.5 0 013.5-3.5h12.25a3.5 3.5 0 013.5 3.5v7.874a3.5 3.5 0 01-3.5 3.5H7.875a3.5 3.5 0 01-3.5-3.5v-7.875zm1.75 0c0-.967.784-1.75 1.75-1.75h12.25c.966 0 1.75.783 1.75 1.75v7.874a1.75 1.75 0 01-1.75 1.75H7.875a1.75 1.75 0 01-1.75-1.75v-7.875z"
			/>
		</g>
	);
}

function MobilePreview(props) {
	return (
		<g>
			<motion.path d="M12.25 20.125a.438.438 0 000 .875h3.5a.438.438 0 000-.875h-3.5z" variants={iconVariants} />
			<motion.path
				variants={iconVariants}
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.875 7a3.5 3.5 0 013.5-3.5h5.25a3.5 3.5 0 013.5 3.5v14a3.5 3.5 0 01-3.5 3.5h-5.25a3.5 3.5 0 01-3.5-3.5V7zm1.75 0c0-.966.784-1.75 1.75-1.75h5.25c.966 0 1.75.784 1.75 1.75v14a1.75 1.75 0 01-1.75 1.75h-5.25A1.75 1.75 0 019.625 21V7z"
			/>
		</g>
	);
}
