import React from "react";
import styled from "styled-components";

const BottomBarWrap = styled.div`

`;

export const BottomBar = props => {
	return (
		<BottomBarWrap>

		</BottomBarWrap>
	);
};
