import { tiles } from "../page/Tile";

export const TestTome = [
	{
		id: "page1",
		order: 1,
		tiles: [
			{
				id: "tile1",
				order: 1,
				left: 0,
				width: 12,
				type: tiles.TEXT.name,
				params: {},
			},
		],
	},
];
