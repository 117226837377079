import {
	createTomeRecentsList,
	doc2TomeRecentsList,
	createPageRecentsList,
	createImagesRecentsList,
	themesList,
	themesFallbackList,
} from "./PromptConstants";

export const rootCommands = [
	{
		id: "command_create_tome",
		label: "Create presentation",
		icon: "PagesStacked",
		placeholder: "Describe the content",
		buildLabel: "presentation",
		finishedLabel: "Presentation",
		action: "SCOPED",
		recentsList: createTomeRecentsList,
		generative: true,
		noContentModifier: -1,
		keywords: ["generate", "generate presentation", "story", "outline"],
		rootScore: 1,
        isFallback: true,
	},
	{
		id: "command_doc_2_tome",
		label: "Document to presentation",
		icon: "Doc",
		placeholder: "Paste text from a document",
		buildLabel: "presentation",
		finishedLabel: "Presentation",
		action: "SCOPED",
		//recentsList: doc2TomeRecentsList,
		generative: true,
		noContentModifier: -1,
		keywords: ["generate"],
		rootScore: 0.999,
	},
	{
		id: "command_create_page",
		label: "Create page",
		icon: "PageLandscape",
		placeholder: "Describe the content",
		finishedLabel: "Page",
		buildLabel: "page",
		action: "SCOPED",
		recentsList: createPageRecentsList,
		generative: true,
		keywords: ["generate page", "add page"],
		rootScore: 0.998,
        isFallback: true,
	},
	{
		id: "command_create_image",
		label: "Create image of",
		icon: "Photo",
		placeholder: "Describe the image",
		action: "SCOPED",
		finishedLabel: "Image",
		buildLabel: "image",
		recentsList: createImagesRecentsList,
		generative: true,
		keywords: ["generate", "dalle", "add image", "make image"],
		rootScore: 0.997,
        isFallback: true,
	},
	{
		id: "command_search_image",
		label: "Search for image",
		icon: "Unsplash",
		placeholder: "Describe the image",
		action: "SCOPED",
		finishedLabel: "Image",
		buildLabel: "image",
		recentsList: createImagesRecentsList,
		generative: true,
		keywords: ["generate", "dalle", "add image", "make image"],
		rootScore: 0.997,
	},

    {
		id: "command_create_tome_from_webpage",
		label: "Create presentation from webpage",
		icon: "PagesStacked",
		placeholder: "What's the URL?",
		buildLabel: "presentation",
		finishedLabel: "Presentation",
		action: "SCOPED",
		generative: true,
        isFallback: false,
		noContentModifier: -1,
		keywords: ["http", "web", "import", "url"],
		rootScore: 0.996,
	},

    {
		id: "command_create_page_from_webpage",
		label: "Create page from webpage",
		icon: "PageLandscape",
		placeholder: "What's the URL?",
		finishedLabel: "Page",
		buildLabel: "page",
		action: "SCOPED",
		generative: true,
        isFallback: false,
		keywords: ["http", "web", "import", "url"],
		rootScore: 0.995,
	},

	// {
	// 	id: "change_tone",
	// 	label: "Rewrite",
	// 	icon: "Wavy",
	// 	placeholder: "Type a tone",
	// 	action: "SCOPED",
	// 	childList: tonesList,
	// 	fallbackList: tonesFallbackList,
	// 	rootScore: 0.964,
	// },

	// {
	// 	id: "recent_prompts",
	// 	label: "Recents",
	// 	icon: "Clock",
	// 	placeholder: "Type a prompt",
	// 	action: "SCOPED",
	// 	childList: recentPromptsList,
	// 	fallbackList: recentsFallbackList,
	// 	rootScore: 0.76,
	// },

	{
		id: "command_add_tile_text",
		label: "Add text tile",
		icon: "Text",
		placeholder: "",
		action: "INSTANT",
		keywords: ["new text tile", "title"],
		rootScore: 0.93,
	},
	{
		id: "command_add_tile_image",
		label: "Add image tile",
		icon: "Photo",
		placeholder: "",
		action: "INSTANT",
		keywords: ["new image tile", "picture", "photo", "media"],
		rootScore: 0.92,
	},
	{
		id: "command_add_tile_video",
		label: "Add video tile",
		icon: "Video",
		placeholder: "",
		action: "INSTANT",
		keywords: ["new video tile", "picture", "photo", "media", "clip"],
		rootScore: 0.92,
	},
	{
		id: "command_add_tile_table",
		label: "Add table tile",
		icon: "Table",
		placeholder: "",
		action: "INSTANT",
		keywords: ["new table tile", "sheet", "spreadsheet"],
		rootScore: 0.92,
	},
	{
		id: "command_add_tile_web",
		label: "Add web tile",
		icon: "Link",
		placeholder: "",
		action: "INSTANT",
		rootScore: 0.92,
		keywords: ["New web tile", "Embed", "Link", "Content"],
	},
	
	{
		id: "command_add_tile_giphy",
		label: "Add Giphy tile",
		icon: "Giphy",
		placeholder: "",
		action: "INSTANT",
		keywords: ["New Giphy tile", "GIF"],
		rootScore: 0.92,
	},
	{
		id: "command_add_tile_figma",
		label: "Add Figma tile",
		icon: "Figma",
		placeholder: "",
		action: "INSTANT",
		keywords: ["New Figma tile", "Design", "Mock"],
		rootScore: 0.92,
	},
	{
		id: "command_add_tile_airtable",
		label: "Add Airtable tile",
		icon: "Airtable",
		placeholder: "",
		action: "INSTANT",
		keywords: ["New Airtable tile", "Table", "Sheet", "Spreadsheet"],
		rootScore: 0.92,
	},

	{
		id: "command_add_tile_twitter",
		label: "Add Twitter tile",
		icon: "Twitter",
		placeholder: "",
		action: "INSTANT",
		keywords: ["New Twitter tile", "Tweet", "Twitter"],
		rootScore: 0.92,
	},
	{
		id: "command_add_tile_framer",
		label: "Add Framer tile",
		icon: "Framer",
		placeholder: "",
		action: "INSTANT",
		keywords: ["New Framer tile", "Design"],
		rootScore: 0.92,
	},
	{
		id: "command_add_tile_miro",
		label: "Add Miro tile",
		icon: "Miro",
		placeholder: "",
		action: "INSTANT",
		keywords: ["New Miro tile", "Brainstorm"],
		rootScore: 0.92,
	},
	{
		id: "command_add_tile_looker",
		label: "Add Looker tile",
		icon: "Looker",
		placeholder: "",
		action: "INSTANT",
		keywords: ["New Looker tile", "Data", "Analytics", "Visualization", "Chart", "Graph"],
		rootScore: 0.92,
	},
	
	{
		id: "command_add_page",
		label: "New page",
		icon: "PlusLandscape",
		placeholder: "",
		action: "INSTANT",
		shortcut: "⌥ N",
		rootScore: 0.79,
	},
	{
		id: "change_theme",
		label: "Set theme",
		icon: "ColorPaletteFill",
		placeholder: "Type a theme name",
		action: "SCOPED",
		childList: themesList,
		fallbackList: themesFallbackList,
		keywords: ["font", "color", "background"],
		rootScore: 0.966,
	},
	// {
	// 	id: "change_page_theme",
	// 	label: "Set page theme",
	// 	icon: "ColorPaletteFill",
	// 	placeholder: "Type a theme name",
	// 	action: "SCOPED",
	// 	childList: themesList,
	// 	fallbackList: themesFallbackList,
	// 	keywords: ["font", "color", "background"],
	// 	rootScore: 0.965,
	// },
	// {
	// 	id: "command_set_theme",
	// 	label: "Set theme",
	// 	icon: "ColorPaletteFill",
	// 	placeholder: "",
	// 	action: "INSTANT",
	// 	shortcut: "⌥ O",
	// },
	{
		id: "command_record_narration",
		label: "Record narration",
		icon: "Record",
		placeholder: "",
		action: "INSTANT",
		shortcut: "⌥ R",
		keywords: ["video", "loom", "narrate"],
		rootScore: 0.78,
	},
	{
		id: "command_add_logo",
		label: "Add logo",
		icon: "PlusSquareOutline",
		placeholder: "",
		action: "INSTANT",
		shortcut: "⌥ T",
		rootScore: 0.77,
	},
	

	{
		id: "command_play_mode",
		label: "Play",
		icon: "PlayFilled",
		placeholder: "",
		action: "INSTANT",
		shortcut: "⌥ T",
		rootScore: 0.76,
	},
	{
		id: "command_open_share",
		label: "Share",
		icon: "Share",
		placeholder: "",
		action: "INSTANT",
		shortcut: "⌥ T",
		rootScore: 0.75,
	},
	{
		id: "command_copy_tome_link",
		label: "Copy tome link",
		icon: "Share",
		placeholder: "",
		action: "INSTANT",
		shortcut: "⌥ T",
		rootScore: 0.74,
	},
	
	{
		id: "command_rename_tome",
		label: "Rename tome",
		icon: "Rename",
		placeholder: "",
		action: "INSTANT",
		shortcut: "⌥ T",
		rootScore: 0.74,
	},
	{
		id: "command_duplicate_tome",
		label: "Duplicate tome",
		icon: "DuplicateSquare",
		placeholder: "",
		action: "INSTANT",
		shortcut: "⌥ T",
		rootScore: 0.74,
	},
	{
		id: "export_to_pdf",
		label: "Export to PDF",
		icon: "Download",
		action: "DOWNLOAD",
		buildLabel: "PDF",
		finishedLabel: "PDF",
		rootScore: 0.62,
	},
	{
		id: "command_delete_tome",
		label: "Delete tome",
		icon: "Delete",
		action: "INSTANT",
		rootScore: 0.62,
	},
	
	{
		id: "prompt_writing_tips",
		label: "Prompt writing tips",
		icon: "Lightbulb",
		action: "LINK",
		link: "https://tome.app/tomecommunity/prompt-guide-tips-for-crafting-generative-prompts-clbtp8jdh0b5c4w5x3p596esr",
		keywords: ["help", "about", "?"],
		rootScore: 0.69,
	},
	{
		id: "getting_started_guide",
		label: "Getting started guide",
		icon: "Book",
		action: "LINK",
		link: "https://tome.app/tomecommunity/tome-getting-started-guide-cl5sigulu1899274x5iqictrjgv",
		keywords: ["help", "about", "?"],
		rootScore: 0.68,
	},
	{
		id: "command_send_us_message",
		label: "Send us a message",
		icon: "CommentFill",
		action: "LINK",
		link: "https://tome.app/tomecommunity/tome-getting-started-guide-cl5sigulu1899274x5iqictrjgv",
		keywords: ["help", "about", "?"],
		rootScore: 0.68,
	},
];

export const textTileCommands = [
	{
		id: "command_tile_text_rewrite",
		label: "Rewrite",
		icon: "DoubleSparkle",
		action: "INSTANT",
		keywords: ["reword", "write"],
		rootScore: 0,
        tileScore: 1,
	},
	{
		id: "command_tile_text_reduce",
		label: "Reduce",
		icon: "ShortenText",
		action: "INSTANT",
		keywords: ["reword", "write"],
		rootScore: 0,
        tileScore: 1,
	},
	{
		id: "command_tile_text_extend",
		label: "Extend",
		icon: "LengthenText",
		action: "INSTANT",
		keywords: ["reword", "write"],
		rootScore: 0,
        tileScore: 1,
	},
	
    {
		id: "command_tile_text_adjust_tone_neutral",
		label: "Adjust tone: Neutral",
		icon: "Wavy",
        action: "INSTANT",
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_text_adjust_tone_friendly",
		label: "Adjust tone: Friendly",
		icon: "Wavy",
        action: "INSTANT",
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_text_adjust_tone_excited",
		label: "Adjust tone: Excited",
		icon: "Wavy",
        action: "INSTANT",
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_text_adjust_tone_persuasive",
		label: "Adjust tone: Persuasive",
		icon: "Wavy",
        action: "INSTANT",
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_text_adjust_tone_intellectual",
		label: "Adjust tone: Intellectual",
		icon: "Wavy",
        action: "INSTANT",
		rootScore: 0,
        tileScore: 1,
	},
    
    {
		id: "command_tile_text_align_left",
		label: "Align left",
		icon: "AlignLeft",
        action: "INSTANT",
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_text_align_center",
		label: "Align center",
		icon: "AlignCenter",
        action: "INSTANT",
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_text_align_right",
		label: "Align right",
		icon: "AlignRight",
        action: "INSTANT",
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_text_align_top",
		label: "Align top",
		icon: "AlignTop",
        action: "INSTANT",
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_text_align_middle",
		label: "Align middle",
		icon: "AlignMiddle",
        action: "INSTANT",
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_text_align_bottom",
		label: "Align bottom",
		icon: "AlignBottom",
        action: "INSTANT",
		rootScore: 0,
        tileScore: 1,
	},
    
    {
		id: "command_tile_text_decrease_size",
		label: "Decrease text size",
		icon: "TextSizeDecrease",
        action: "INSTANT",
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_text_increase_size",
		label: "Increase text size",
		icon: "TextSizeIncrease",
        action: "INSTANT",
		rootScore: 0,
        tileScore: 1,
	},
    
    {
		id: "command_tile_text_style_display",
		label: "Set style: Display",
		icon: "Text",
        action: "INSTANT",
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_text_style_title",
		label: "Set style: Title",
		icon: "Text",
        action: "INSTANT",
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_text_style_heading",
		label: "Set style: Heading",
		icon: "Text",
        action: "INSTANT",
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_text_style_subheading",
		label: "Set style: Subheading",
		icon: "Text",
        action: "INSTANT",
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_text_style_body",
		label: "Set style: Body",
		icon: "Text",
        action: "INSTANT",
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_text_style_caption",
		label: "Set style: Caption",
		icon: "Text",
        action: "INSTANT",
		rootScore: 0,
        tileScore: 1,
	},
    
    {
		id: "command_tile_text_list_bulleted",
		label: "Add style: bulleted list",
		icon: "ListBullet",
        action: "INSTANT",
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_text_list_ordered",
		label: "Add style: ordered list",
		icon: "ListNumber",
        action: "INSTANT",
		rootScore: 0,
        tileScore: 1,
	},
    
];

export const imageTileCommands = [
    {
		id: "command_tile_image_upload",
		label: "Upload",
		icon: "Upload",
		action: "INSTANT",
		keywords: ["",""],
		rootScore: 0,
        tileScore: 1,
	},
    
	{
		id: "command_tile_image_size_fit",
		label: "Fit",
		icon: "FitLandscape",
		action: "INSTANT",
		keywords: ["",""],
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_image_size_fill",
		label: "Fill",
		icon: "FillLandscape",
		action: "INSTANT",
		keywords: ["",""],
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_image_size_adjust",
		label: "Adjust",
		icon: "Crop",
		action: "INSTANT",
		keywords: ["",""],
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_image_add_caption",
		label: "Add caption",
		icon: "Text",
		action: "INSTANT",
		keywords: ["",""],
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_image_background_color",
		label: "Change background color",
		icon: "ColorPaletteFill",
		action: "INSTANT",
		keywords: ["",""],
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_image_download",
		label: "Download image",
		icon: "Download",
		action: "INSTANT",
		keywords: ["",""],
		rootScore: 0,
        tileScore: 1,
	},
    
];

export const videoTileCommands = [
    {
		id: "command_tile_video_upload",
		label: "Upload",
		icon: "Upload",
		action: "INSTANT",
		keywords: ["",""],
		rootScore: 0,
        tileScore: 1,
	},
	{
		id: "command_tile_video_size_fit",
		label: "Fit",
		icon: "FitLandscape",
		action: "INSTANT",
		keywords: ["",""],
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_video_size_fill",
		label: "Fill",
		icon: "FillLandscape",
		action: "INSTANT",
		keywords: ["",""],
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_video_size_adjust",
		label: "Adjust",
		icon: "Crop",
		action: "INSTANT",
		keywords: ["",""],
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_video_trim",
		label: "Trim",
		icon: "Trim",
		action: "INSTANT",
		keywords: ["",""],
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_video_autoplay_enable",
		label: "Enable autoplay",
		icon: "PlaybackPlayButton",
		action: "INSTANT",
		keywords: ["",""],
		rootScore: 0,
        tileScore: 1,
	},
	{
		id: "command_tile_video_autoplay_enable",
		label: "Disable autoplay",
		icon: "PlaybackPlayButton",
		action: "INSTANT",
		keywords: ["",""],
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_video_repeat_enable",
		label: "Enable repeat",
		icon: "TwoArrowCirclePathCounterclockwise",
		action: "INSTANT",
		keywords: ["",""],
		rootScore: 0,
        tileScore: 1,
	},
	{
		id: "command_tile_video_repeat_enable",
		label: "Disable repeat",
		icon: "TwoArrowCirclePathCounterclockwise",
		action: "INSTANT",
		keywords: ["",""],
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_video_add_caption",
		label: "Add caption",
		icon: "Text",
		action: "INSTANT",
		keywords: ["",""],
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_video_background_color",
		label: "Change background color",
		icon: "ColorPaletteFill",
		action: "INSTANT",
		keywords: ["",""],
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_video_download",
		label: "Download video",
		icon: "Download",
		action: "INSTANT",
		keywords: ["",""],
		rootScore: 0,
        tileScore: 1,
	},
    
];

export const tableTileCommands = [
    {
		id: "command_tile_table_add_row",
		label: "Add row",
		icon: "AddTableRow",
		action: "INSTANT",
		keywords: ["",""],
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_table_add_column",
		label: "Add column",
		icon: "AddTableColumn",
		action: "INSTANT",
		keywords: ["",""],
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_table_remove_row",
		label: "Remove row",
		icon: "RemoveTableRow",
		action: "INSTANT",
		keywords: ["",""],
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_table_remove_column",
		label: "Remove column",
		icon: "RemoveTableColumn",
		action: "INSTANT",
		keywords: ["",""],
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_table_distribute_columns",
		label: "Distribute columns",
		icon: "ArrowLeftRightOut",
		action: "INSTANT",
		keywords: ["",""],
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_table_wrap_text",
		label: "Wrap text",
		icon: "WrapText",
		action: "INSTANT",
		keywords: ["",""],
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_table_unwrap_text",
		label: "Unwrap text",
		icon: "UnwrapText",
		action: "INSTANT",
		keywords: ["",""],
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_table_show_title",
		label: "Show title",
		icon: "Title",
		action: "INSTANT",
		keywords: ["",""],
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_table_hide_title",
		label: "Hide title",
		icon: "Title",
		action: "INSTANT",
		keywords: ["",""],
		rootScore: 0,
        tileScore: 1,
	},
]

export const tileCommands = [
    {
		id: "command_tile_cut",
		label: "Cut",
		icon: "Cut",
        action: "INSTANT",
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_copy",
		label: "Copy",
		icon: "Copy",
        action: "INSTANT",
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_paste",
		label: "Paste",
		icon: "Paste",
        action: "INSTANT",
		rootScore: 0,
        tileScore: 1,
	},
    {
		id: "command_tile_duplicate",
		label: "Duplicate",
		icon: "DuplicateLandscape",
        action: "INSTANT",
		rootScore: 0,
        tileScore: 1,
	},
	{
		id: "command_tile_delete",
		label: "Delete",
		icon: "Delete",
        action: "INSTANT",
		rootScore: 0,
        tileScore: 1,
	},
    
];